import React from 'react';
import logo from './logo.svg';
import './App.css';

export default class App extends React.Component {
  constructor() {
    super()
    this.state = {
      browserTime: new Date(),
    }
  }

  componentDidMount() {
    this.timerId = setInterval(
      () => this.tick(),
      1000
    )
  }

  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  tick() {
    this.setState({
      browserTime: new Date()
    })
  }

  render() {
    const { browserTime } = this.state
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Current local browser date and time: {browserTime.toLocaleString()}
          </p>
        </header>
      </div>
    );
  }
}
